<template>
  <div class="position-relative news-item" @click="goToNewsDetail(list.newsid)">
    <div class="date position-absolute start-0">{{ list.create_at }}</div>
    <div
      class="img-box"
      :style="{ background: list.image ? 'rgba(186, 186, 186, 1)' : 'none' }"
    >
      <img :src="list.image" class="news-img" />
    </div>
    <h5 class="text-truncate">
      {{ list.title }}
    </h5>
    <p>
      {{ list.desc }}
    </p>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
defineProps({
  list: { type: Object }
})

const router = useRouter()
const goToNewsDetail = (newsid) => {
  router.push({
    path: '/NewsCenter/NewsDetail',
    query: { newsid: newsid }
  })
}
</script>
<style lang="stylus" scoped>
.news-item
  border-radius: 5px;
  height 313px
  cursor pointer
  .img-box
    width 100%;
    border-radius: 5px;
    overflow hidden
    position relative
    // background rgba(186, 186, 186, 1)
    height 200px
    &::after
      content: "";
      width: 100%;
      height: 0%;
      background-color: rgba(0,0,0,0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: all 0.5s ease-in-out 0s;
    .news-img
      width 100%
      height 100%
      object-fit cover
      transition: all 0.5s ease-in-out 0s;
  .date
    height 32px
    width 106px
    font-size 16px
    line-height 32px
    font-family: Source Han Sans CN;
    font-weight: 800;
    color #fff
    background rgba(32, 155, 215, .5)
    // border-top-left-radius 5px
    // border-bottom-left-radius 5px
  h5
    margin-top 30px
    font-size 20px
    margin-bottom 10px
    color: #333333;
  p
    font-size 16px
    color: #999999;
    display -webkit-box
    overflow hidden
    text-overflow ellipsis
    -webkit-box-orient vertical
    -webkit-line-clamp 2
    margin-bottom 0
  &:hover
    .news-img
      transform scale(1.2)
    .img-box
      &::after
        height 100%
        opacity 1
</style>
