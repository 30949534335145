<template>
  <!--Main Slider Start-->
  <section class="main-slider">
    <div
      class="swiper-container thm-swiper__slider"
      data-swiper-options='{"slidesPerView": 1, "loop": true,
    "effect": "fade",
     "pagination": {
        "el": "#main-slider-pagination",
        "type": "bullets",
        "clickable": true
      },
    "navigation": {
        "nextEl": "#main-slider__swiper-button-next",
        "prevEl": "#main-slider__swiper-button-prev"
    },
    "autoplay": {
        "delay": 5000
    }}'
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide d-flex justify-content-center align-items-center"
          v-for="item in swiperData"
          :key="item"
        >
          <div class="img-box">
            <div
              class="image-layer"
              :style="`background-image: url(${item.image})`"
            ></div>
            <div class="box"></div>
          </div>
          <!-- /.image-layer -->
          <div class="main-slider__social" v-if="false">
            <a href="#">facebook</a>
            <a href="#">twitter</a>
            <a href="#">instagram</a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-slider__content">
                  <div class="main-slider__title-box-1">
                    <p>{{ item.subtitle }}</p>
                    <h6>{{ item.title }}</h6>
                    <p>
                      {{ item.desc }}
                    </p>
                    <div class="main-slider__title-box-2" v-if="false">
                      <p>{{ item.subtitle }}</p>
                      <h6>{{ item.title }}</h6>
                      <p>{{ item.desc }}</p>
                    </div>
                  </div>

                  <div class="main-slider-badge">
                    <img
                      data-tilt
                      src="assets/images/resources/main-slider-badge.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" v-if="false">
          <div class="img-box">
            <div
              class="image-layer"
              :style="`background-image: url(${require('../assets/18.png')})`"
            ></div>
            <div class="box"></div>
          </div>
          <!-- /.image-layer -->
          <div class="main-slider__social">
            <a href="#">facebook</a>
            <a href="#">twitter</a>
            <a href="#">instagram</a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-slider__content">
                  <div class="main-slider__title-box-1">
                    <h2>
                      Creative <br />
                      Talent <br />
                      Here
                    </h2>
                    <div class="main-slider__title-box-2">
                      <h2>
                        Creative <br />
                        Talent <br />
                        Here
                      </h2>
                    </div>
                  </div>
                  <a href="about.html" class="thm-btn">Discover More</a>
                  <div class="main-slider-badge">
                    <img data-tilt src="require('/assets/18.png')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-slide" v-if="false">
          <div class="img-box">
            <div
              class="image-layer"
              :style="`background-image: url(${require('../assets/18.png')})`"
            ></div>
            <div class="box"></div>
          </div>
          <!-- /.image-layer -->
          <div class="main-slider__social" v-if="false">
            <a href="#">facebook</a>
            <a href="#">twitter</a>
            <a href="#">instagram</a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-slider__content">
                  <div class="main-slider__title-box-1">
                    <h2>
                      Creative <br />
                      Talent <br />
                      Here
                    </h2>
                    <div class="main-slider__title-box-2">
                      <h2>
                        Creative <br />
                        Talent <br />
                        Here
                      </h2>
                    </div>
                  </div>
                  <a href="about.html" class="thm-btn">Discover More</a>
                  <div class="main-slider-badge">
                    <img
                      data-tilt
                      src="assets/images/resources/main-slider-badge.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- If we need navigation buttons -->
      <div class="slider-bottom-box clearfix">
        <div class="swiper-pagination" id="main-slider-pagination"></div>
        <div class="main-slider__nav">
          <div class="swiper-button-prev" id="main-slider__swiper-button-next">
            <span class="icon-arrow-left"></span>
          </div>
          <div class="swiper-button-next" id="main-slider__swiper-button-prev">
            <span class="icon-arrow-right"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Main Slider End-->
</template>
<script setup>
import Swiper from 'swiper'
import { nextTick, defineProps, watch } from 'vue'
const props = defineProps({
  swiperData: {
    type: Array,
    default: function () {
      return []
    }
  }
})
// eslint-disable-next-line space-before-function-paren
function thmSwiperInit() {
  // swiper slider
  const swiperElm = document.querySelectorAll('.thm-swiper__slider')
  // if (swiperElm) {
  //   return
  // }
  swiperElm.forEach((swiperelm) => {
    const swiperOptions = JSON.parse(swiperelm.dataset.swiperOptions)
    // eslint-disable-next-line no-unused-vars
    const thmSwiperSlider = new Swiper(swiperelm, swiperOptions)
  })
}
watch(
  () => props.swiperData,
  async (newVal) => {
    if (newVal.length >= 1) {
      await nextTick()
      thmSwiperInit()
    }
  }
)

// 、、、
</script>

<style lang="stylus" scoped>
.swiper-container
  height 100%
.main-slider
  height 100%
.img-box
  position absolute
  left 0
  top 135px
  bottom 0
  right 0
  width 100%
  overflow hidden
  background #fff
  .box
    position absolute
    left 0
    top 0
    right 0
    bottom  0
    background rgba(32, 155, 215, .7)
    z-index 10
.slider-bottom-box
  @media screen and (max-width 768px) {
    bottom 90px
  }
.main-slider .main-slider__title-box-1 p
  @media screen and (max-width 768px) {
    font-size 14px
  }
.main-slider .main-slider__title-box-1 h6
  font-weight bold
  letter-spacing 5px
  @media screen and (max-width 768px) {
   font-size 40px
   letter-spacing 2px
  }
</style>
