<template>
  <div v-show="!isSearchPage">
    <div class="index-container position-relative">
      <Swiper :swiperData="banner"></Swiper>
      <VideoItem
        class="index-video mx-auto position-absolute start-50 translate-middle-x"
        :video="video"
        v-if="video != ''"
      >
      </VideoItem>
    </div>
    <div class="business-box text-center" v-if="business.length !== 0">
      <h5>业务板块</h5>
      <small> MAIN BUSINESS SECTOR</small>
      <div class="line mx-auto mt-2"></div>
      <div class="circle-box position-relative mx-auto">
        <img
          :src="`${require('../assets/19.png')}`"
          class="w-99 h-99 circle-img"
          v-show="businessIndex === 1"
        />
        <img
          :src="`${require('../assets/20.png')}`"
          class="w-99 h-99 circle-img"
          v-show="businessIndex === 2"
        />
        <img
          :src="`${require('../assets/21.png')}`"
          class="w-99 h-99 circle-img"
          v-show="businessIndex === 3"
        />
        <div
          class="item top position-absolute translate-middle-x start-50"
          :class="{ active: businessIndex === 1 }"
          @click="goToBusiness('Land')"
        >
          <h6 class="h6_1">{{ business[0].name }}</h6>
          <p class="text-left">
            {{ business[0].desc }}
          </p>
          <i class="bi bi-caret-down-fill"></i>
        </div>
        <div
          class="item left position-absolute"
          :class="{ active: businessIndex === 2 }"
          @click="goToBusiness('building')"
        >
          <h6 class="h6_2">{{ business[1].name }}</h6>
          <p class="text-left">
            {{ business[1].desc }}
          </p>
          <i
            class="bi bi-caret-right-fill position-absolute left-bi top-50 translate-middle-y"
          ></i>
        </div>
        <div
          class="item right position-absolute"
          :class="{ active: businessIndex === 3 }"
          @click="goToBusiness('Estate')"
        >
          <h6 class="h6_3">{{ business[2].name }}</h6>
          <p class="text-left">
            {{ business[2].desc }}
          </p>
          <i
            class="bi bi-caret-left-fill position-absolute right-bi top-50 translate-middle-y"
          ></i>
        </div>
      </div>
    </div>
    <div class="news-box text-center">
      <h5>新闻动态</h5>
      <small>OUR NEWS AND SERVICES</small>
      <div class="line mx-auto mt-2"></div>
      <newsColumn style="margin-top: 50px" :newsList="news"></newsColumn>
      <div class="look-more mx-auto" @click="lookMoreNews">查看更多 ></div>
    </div>
  </div>
  <router-view></router-view>
  <SwimBox v-if="false" />
</template>
<script setup>
import { onMounted, ref, onUnmounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Swiper from '@/components/swiper'
import VideoItem from '@/components/VideoItem.vue'
import newsColumn from '../components/newsColumn.vue'
import { getIndex } from '@/server/index.js'
import SwimBox from '@/components/swim_box/swim_box.vue'

const router = useRouter()
const route = useRoute()
let timer = null
const banner = ref([])
const business = ref([])
const video = ref('')
const businessIndex = ref(1)
const news = ref([])
const isSearchPage = computed(() => route.meta.isSearchPage)

onMounted(async () => {
  const result = await getIndex()
  banner.value = result.banner
  business.value = result.business
  video.value = result.video
  news.value = result.news

  timer = setInterval(() => {
    if (businessIndex.value === business.value.length) {
      businessIndex.value = 0
    }
    businessIndex.value++
  }, 3000)
})

onUnmounted(() => {
  clearInterval(timer)
})

const lookMoreNews = () => {
  router.push({
    path: '/NewsCenter'
  })
}

const goToBusiness = (path) => {
  router.push({
    path: `/BusinessSection/${path}`
  })
}
</script>
<style lang="stylus" scoped>
.index-container
  margin-top -135px
  height 100vh
  .index-video
    width 50%
    height 300px
    bottom -150px
    z-index 22
    /deep/.text
      display none
    @media screen and (max-width 768px){
      width 80%
      height 150px
      bottom -75px
    }
.business-box
  padding-top 260px
  padding-bottom 78px
  background url('../assets/business.png') no-repeat
  background-size cover
  background-position center center
  @media screen and (max-width 768px) {
    padding-top 100px
  }
  h5
    font-size 40px
    margin-bottom 20px
  small
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #999999;
    font-size 18px
    letter-spacing:4px
  .line
    width: 110px;
    height: 2px;
    background: #209BD7;
    margin-top 20px
  .circle-box
    width 768px
    height 454px
    margin-top 142px
    background url('../assets/bus-bj.png') center no-repeat
    background-size cover
    @media screen and (max-width 768px) {
      width 300px
      height 177.4px
      margin-top 70px
    }
    .item
      width 183px
      color rgba(51, 51, 51, 1)
      cursor pointer
      &.top
        top -16%
        margin-left 15px
      &.left
        left -11%
        top 48%
      &.right
        right -11%
        top 48%
      @media screen and (max-width 768px)
        width 70px
      &.active
        color rgba(32, 155, 215, 1)
        h6
          color rgba(32, 155, 215, 1)
        .bi
          font-size 27px
          display block
      h6
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        @media screen and (max-width 768px){
          font-size 16px
          margin-bottom 0
        }
        &.h6_2
          @media screen and (max-width 768px)
            text-align left
         &.h6_3
          @media screen and (max-width 768px)
            text-align right
      p
        font-size 14px
        @media screen and (max-width 768px){
          font-size 12px
          display none
        }
      .bi
        font-size 27px
        display none
        &.left-bi
          right -30px
        &.right-bi
          left -30px
    .circle-img
      width 99%
      height 99%
      transition all 1s ease
.news-box
  background #fff
  padding-top 65px
  padding-bottom 80px
  h5
    font-size 40px
  small
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #999999;
    font-size 18px
    letter-spacing:2px
  .line
    width: 110px;
    height: 2px;
    background: #209BD7;
  .look-more
    width: 140px;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    color: #fff;
    line-height 50px
    cursor pointer
    background: #209bd7;
    transition: all 0.3s;
    &:hover
      background: #10366b;
</style>
