<template>
  <div
    class="video-item-container position-relative"
    v-bind="attrs"
    @click="modelShow = true"
  >
    <slot>
      <img :src="video.image" class="bj-img" />
      <p class="text">
        {{ video.title }}
      </p>
    </slot>
    <div
      class="btn-icon position-absolute start-50 translate-middle top-50 rounded-circle text-center"
    >
      <!-- <i
        class="bi bi-caret-right-fill"
        style="color: rgba(255, 168, 0, 1); font-size: 20px"
      ></i> -->
    </div>
    <VideoModel
      v-model:modelShow="modelShow"
      v-if="modelShow"
      :videoSrc="video.video"
    ></VideoModel>
  </div>
</template>
<script setup>
import { useAttrs, ref, defineProps } from 'vue'
import VideoModel from './videoModel.vue'
const attrs = useAttrs()
const modelShow = ref(false)
defineProps({
  video: {
    type: Object
  }
})
</script>
<style lang="stylus" scoped>
.video-item-container
  background:#fff
  position relative
  width 100%
  height 100%
  cursor pointer
  .btn-icon
    width 80px
    height 80px
    // background #fff
    // border 12px solid rgba(0,0,0,.5)
    cursor pointer
    background url('../assets/5.png') no-repeat
    background-size cover
/deep/.modal
  display block
.bj-img
    width 100%
    height 100%
    object-fit cover
.text
  position absolute
  left 0
  right 0px
  bottom 0
  height: 50px;
  background: rgba(16, 54, 107, .8)
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  padding 0 20px
  line-height 50px
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
  word-break break-all
  margin-bottom 0
</style>
