<template>
  <teleport to="#videoId">
    <div class="model-box position-fixed start-0 end-0 top-0 bottom-0">
      <div class="container top-50 position-absolute start-50 translate-middle">
        <div class="row justify-content-center">
          <div
            class="col-xxl-8 video col-md-10 col-10 col-11 position-relative"
          >
            <video
              class="videoUrl"
              :src="videoSrc"
              ref="video"
              controls
            ></video>
            <i class="bi bi-x position-absolute" @click.prevent="hideModel"></i>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import { useDomCreate } from '../hooks/useDomCreate'
useDomCreate('videoId')
defineProps({
  modelShow: {
    type: Boolean,
    default: false
  },
  videoSrc: { type: String }
})
const emit = defineEmits(['update:modelShow'])
const hideModel = () => {
  emit('update:modelShow', false)
}
const video = ref(null)
onMounted(() => {
  video.value.play()
})
// const clickVideo = () => {
//   console.log('video', video.value.paused)
//   video.value.play()
//   if (video.value.paused) {
//     video.value.play()
//   } else {
//     video.value.pause()
//   }
// }
</script>

<style scoped>
.model-box {
  background: rgba(0, 0, 0, 0.8);
  z-index: 2222;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video {
  background: #fff;
  height: 506px;
  padding: 0 !important;
}
.bi-x {
  top: -50px;
  font-size: 40px;
  right: -10px;
  color: #fff;
  cursor: pointer;
  text-align: right;
}
.videoUrl {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
